<template>
  <div class="text-container">
    <p>
      Kennen Sie Ihre betriebswirtschaftlichen Zahlen und können Sie diese
      deuten??
      <br />
      Nur wer Klarheit über den wirtschaftlichen Stand seiner Apotheke hat, kann
      auch beruhigt in die Zukunft sehen.
    </p>
    <ul>
      <li>Gesetzte Ziele kontrollieren</li>
      <li>Bei Abweichungen ggfls. Gegenmaßnahmen einleiten</li>
      <li>Auf Veränderungen reagieren</li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir führen für Sie ein Benchmarking Ihrer Apotheke durch und helfen Ihnen bei der Deutung von betriebswirtschaftlichen Zahlen.",
      },
    ],
  },
};
</script>